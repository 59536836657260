import PropTypes from "prop-types"
import React, { useCallback, useEffect } from "react"
import { connect } from "react-redux"

import { fetchLandingElements } from "app-store-v2/landing/actions"
import fetchWishlist from "hoc/fetch-wishlist"

export default function withLanding(WrappedComponent) {
  const InternalWithLanding = ({ fetchElements, ...props }) => {
    const all_elements_fetched = props.landing.all_elements_fetched

    const scrollListener = useCallback(() => {
      fetchElements()
    }, [])

    useEffect(() => {
      // Add a listener if all elements haven't been fetched yet
      if (!all_elements_fetched) {
        window.addEventListener("scroll", scrollListener)
      }
      return () => {
        // Remove the listener
        window.removeEventListener("scroll", scrollListener)
      }
      // Only fire this effect if all_elements_fetched changed
    }, [all_elements_fetched])

    return <WrappedComponent {...props} />
  }

  const mapStateToProps = (state, ownProps) => {
    let landing = state.landing
    // load landing from SSR data
    if (
      ownProps.data &&
      ownProps.match.params.collection === ownProps.data.url_path
    ) {
      landing = ownProps.data
    }

    return {
      landing,
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      fetchElements: () => {
        dispatch(fetchLandingElements())
      },
    }
  }

  InternalWithLanding.propTypes = {
    fetchElements: PropTypes.func,
    landing: PropTypes.object,
  }

  const WithLanding = connect(
    mapStateToProps,
    mapDispatchToProps
  )(InternalWithLanding)

  return fetchWishlist(WithLanding)
}
