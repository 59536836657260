import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"

const StyledBreadcrumb = styled.ol`
  color: ${(props) => props.theme.colors.grey[50]};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    color: #757575;
    display: inline;
  }

  li + li:before {
    content: "/";
    padding: 0 4px;
  }
`

const InternalBreadcrumb = ({ breadcrumbs }) => {
  return (
    <nav aria-label="Breadcrumb">
      <StyledBreadcrumb>
        {breadcrumbs.map((breadcrumb) => (
          <li key={`breadcrumb_${breadcrumb.name}`}>
            {breadcrumb.url ? (
              <Link data-lc="breadcrumb" to={breadcrumb.url}>
                {breadcrumb.name}
              </Link>
            ) : (
              <>{breadcrumb.name}</>
            )}
          </li>
        ))}
      </StyledBreadcrumb>
    </nav>
  )
}

const mapStateToProps = (state) => {
  return {
    breadcrumbs: state.collection.breadcrumb || [],
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalBreadcrumb.propTypes = {
  breadcrumbs: PropTypes.array,
}

const Breadcrumb = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalBreadcrumb)

export default Breadcrumb
