import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import withCollection from "areas/collection/hoc/with-collection"
import { Box } from "components/common"

const Image = styled.img`
  left: 50%;
  position: absolute;
  top: 50px;
  transform: translateX(-50%);
  z-index: 1;
`

const InternalLoading = ({ showLoading }) => {
  if (showLoading) {
    return (
      <Box position="relative">
        <Image
          data-cy="collection-loading-icon"
          height="175"
          src={require("images/layout/loadinglarge.gif")}
          width="175"
        />
      </Box>
    )
  } else {
    return null
  }
}

InternalLoading.propTypes = {
  showLoading: PropTypes.oneOf(["content", "full"]),
}

const Loading = withCollection(InternalLoading)

export default Loading
