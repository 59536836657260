// URL checker is meant to check a proposed URL path for loading a page against
// the browser's in-memory storage (and localStorage)
// See the fetchCollectionUrls and loadCollectionUrls initializers

export const checkCollectionUrl = (url) => {
  if (
    typeof window !== "undefined" &&
    typeof window.collectionUrls !== "undefined"
  ) {
    return window.collectionUrls.includes(url)
  }

  return null
}
