import { Box } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"

import GenericNotFound from "app/generic-not-found"
import { setExperiment } from "app-store-v2/app/actions"
import fetchCollection from "areas/collection/hoc/fetch-collection"
import Loading from "areas/collection/jewlr/loading"
import Collection from "areas/collection2"
import Shimmers from "areas/collection2/loaders/shimmers"
import ShimmersPortal from "areas/collection2/loaders/shimmers-portal"
import Homepage from "areas/homepage/jewlr"
import Landing from "areas/landing"
import { IS_PORTAL } from "helpers/application"
import { checkCollectionUrl } from "helpers/collection/url-checker"

const InternalLoadCollection = ({
  blackFridayCollectionExp,
  collectionData,
  history,
  landingData,
  landingLoading,
  model,
  mountedPath,
  match,
  pageNotFound,
  setExperiment,
  staticContext,
}) => {
  useEffect(() => {
    const collections_to_run = ["/promise-rings"]
    if (
      model == "collection" &&
      blackFridayCollectionExp &&
      collections_to_run.includes(mountedPath)
    ) {
      setExperiment({ blackFridayCollection: blackFridayCollectionExp })
    }
  }, [blackFridayCollectionExp, mountedPath, model])

  if (pageNotFound) {
    return <GenericNotFound staticContext={staticContext} />
  }

  if (model === "landing" && !landingLoading) {
    if (["homepage", "home-page-b"].includes(landingData.url_path)) {
      return <Homepage data={landingData} match={match} />
    }
    return <Landing data={landingData} match={match} />
  } else if (model === "collection") {
    return <Collection data={collectionData} history={history} />
  } else if (
    match.params &&
    match.params.collection &&
    checkCollectionUrl(match.params.collection)
  ) {
    return IS_PORTAL() ? (
      <ShimmersPortal data-cy="collection-loading-shimmers" />
    ) : (
      <Shimmers data-cy="collection-loading-shimmers" />
    )
  } else {
    return (
      <Box minHeight="300px">
        <Loading />
      </Box>
    )
  }
}

InternalLoadCollection.propTypes = {
  blackFridayCollectionExp: PropTypes.object,
  collectionData: PropTypes.object,
  history: PropTypes.object,
  landingData: PropTypes.object,
  landingLoading: PropTypes.bool,
  match: PropTypes.object,
  model: PropTypes.string,
  mountedPath: PropTypes.string,
  pageNotFound: PropTypes.bool,
  setExperiment: PropTypes.func,
  staticContext: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    blackFridayCollectionExp: state.experiment.blackFridayCollection,
    landingLoading: state.landing.loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setExperiment: (exp) => dispatch(setExperiment(exp)),
})

const LoadCollection = connect(
  mapStateToProps,
  mapDispatchToProps
)(fetchCollection(InternalLoadCollection))

export default LoadCollection
