import { Box, RatioContainer } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import styled from "styled-components"

import { getUrlProps } from "helpers/landing"

import Image from "./image"
import Textarea from "./textarea"

const PositionBlock = styled(Box).attrs((props) => ({
  left: { tablet: "8%" },
  px: { _: 2, tablet: 0 },
  textAlign: "center",
  top: { _: "30px", tablet: "50%" },
  width: { _: "100%", desktop: "350px", tablet: "260px" },
  ...props,
}))`
  position: absolute;
  z-index: 1;
  ${(props) =>
    !props.noTranslate &&
    props.theme.mediaQueries.tablet`
    transform: translateY(-50%);
  `}
`

const BannerLink = styled(Box)`
  height: 100%;
  width: 100%;
`

const ImageBackgroundBanner = ({
  firstElement,
  element,
  lazyload,
  linkClickData,
}) => {
  const blockRef = useRef()
  let textarea = element.textarea

  const Content = () => {
    const urlProps = getUrlProps(
      element?.image?.url,
      null,
      element?.anchor_name || element?.id
    )

    return (
      <div>
        <Box
          display={{ tablet: "flex" }}
          height="100%"
          justifyContent="center"
          position="relative"
        >
          {element.image && (
            <Box aria-label={element.image.alt} {...urlProps}>
              <Image
                contain={element.contain}
                firstElement={!textarea && firstElement}
                image={element.image}
                lazyload={lazyload}
                linkClickData={element?.anchor_name || element?.id}
              />
            </Box>
          )}

          <Box
            contain={!element.contain && !element.containWide}
            containWide={element.containWide}
            height="100%"
            position="absolute"
            top="0"
            width="100%"
          >
            {textarea && (
              <BannerLink
                onClick={() => {
                  if (blockRef.current) {
                    const elem =
                      blockRef.current.querySelector("a") ||
                      blockRef.current.querySelector("button")
                    elem?.click()
                  }
                }}
                pointer={element?.image?.url}
                ref={blockRef}
              >
                <PositionBlock {...textarea.style}>
                  <Textarea
                    element={textarea}
                    firstElement={firstElement}
                    lazyload={lazyload}
                    linkClickData={
                      element?.anchor_name || element?.id || linkClickData
                    }
                    style={textarea.textBlockStyle}
                  />
                </PositionBlock>
              </BannerLink>
            )}
          </Box>
        </Box>
      </div>
    )
  }
  if (element.style && element.style.removeRatio) {
    return (
      <Box {...element.container_style}>
        <Box
          contain={element.contain}
          mb={{ _: 4, desktop: 6 }}
          {...element.style}
        >
          <Content />
        </Box>
      </Box>
    )
  } else {
    return (
      <Box {...element.container_style}>
        <RatioContainer
          contain={element.contain}
          mb={{ _: 4, desktop: 6 }}
          pb={{ _: "131.68%", tablet: "27.6%" }}
          {...element.style}
        >
          <Content />
        </RatioContainer>
      </Box>
    )
  }
}

ImageBackgroundBanner.propTypes = {
  element: PropTypes.object,
  firstElement: PropTypes.bool,
  lazyload: PropTypes.bool,
  linkClickData: PropTypes.string,
}

export default ImageBackgroundBanner
