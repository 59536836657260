import { Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

const InternalTitle = ({ title }) => {
  return (
    <Typography as="h1" fontSize="25px" fontWeight="700" mb={1}>
      {title}
    </Typography>
  )
}

const mapStateToProps = (state) => {
  return {
    title: state.collection.title,
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalTitle.propTypes = {
  title: PropTypes.string,
}

const Title = connect(mapStateToProps, mapDispatchToProps)(InternalTitle)

export default Title
