import { Box, Divider, Flex, Image, Typography } from "@jewlr/storybook/core"
import React from "react"
import styled from "styled-components"

import { IS_NA, jewlr_t } from "helpers/application"

const IconContainer = styled(Flex).attrs({
  gap: { _: 2, desktop: 7, tablet: 4 },
  justifyContent: "center",
})``

const IconBlock = styled.div`
  padding-bottom: 10px;
  text-align: center;
  width: 54px;
  ${(props) => props.theme.mediaQueries.tablet`
    width: 100px;
  `}
`

const IconImage = styled(Image).attrs((props) => ({
  mb: "10px",
  mx: "auto",
  ...props,
}))``

const IconText = styled(Typography).attrs((props) => ({
  fontSize: { _: 10, tablet: 14 },
  fontWeight: 500,
  letterSpacing: 0,
  lineHeight: { _: "11px", tablet: "17px" },
  mx: "auto",
  ...props,
}))``

const Perks = () => {
  const is_na = IS_NA()

  return (
    <Box contain mb={5} px={{ _: 0, tablet: 0 }}>
      <Divider bg="grey.20" height="0.5px" mb={5} mt="20px" />
      <IconContainer>
        <IconBlock>
          <IconImage
            alt={`FREE ${jewlr_t("terms.Shipping")}`}
            height={{ _: "27px", tablet: "54px" }}
            src={require("images/layout/footer/perks/shipping.svg")}
            width={{ _: "32px", tablet: "66px" }}
          />
          <IconText width={{ _: "35px", tablet: "49px" }}>
            FREE {jewlr_t("terms.Shipping")}
          </IconText>
        </IconBlock>

        <IconBlock>
          <IconImage
            alt="One-Year Warranty"
            height={{ _: "26px", tablet: "52px" }}
            src={require("images/layout/footer/perks/1-year-warranty.svg")}
            width={{ _: "28px", tablet: "55px" }}
          />
          <IconText px={{ tablet: 1 }} width={{ _: "38px", tablet: "112px" }}>
            One-Year Warranty
          </IconText>
        </IconBlock>

        <IconBlock>
          <IconImage
            alt="99-Day Returns"
            height={{ _: "26px", tablet: "52px" }}
            src={require("images/layout/footer/perks/99-day-returns.svg")}
            width={{ _: "23px", tablet: "49px" }}
          />
          <IconText px={{ tablet: 2 }} width={{ _: "30px", tablet: "85px" }}>
            99-Day Returns
          </IconText>
        </IconBlock>

        <IconBlock>
          <IconImage
            alt={`Made In ${is_na ? "USA & Canada" : "North America"}`}
            height={{ _: "24px", tablet: "47px" }}
            src={require("images/layout/footer/perks/diamond.svg")}
            width={{ _: "29px", tablet: "58px" }}
          />
          <IconText
            px={{ tablet: "5px" }}
            width={{ _: "54px", tablet: "92px" }}
          >
            Made in {is_na ? "USA & Canada" : "North America"}
          </IconText>
        </IconBlock>
      </IconContainer>
    </Box>
  )
}

export default Perks
