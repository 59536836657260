import { Box, Shimmer, MediaBox } from "@jewlr/storybook/core"
import React from "react"

const FilterAccordionLoading = () => (
  <MediaBox greaterThanOrEqual="desktop" maxWidth="215px" mr={2} width="22%">
    {[...Array(5)].map((_, i) => (
      <Box key={`filter-accordion-loading-${i}`} py={2}>
        <Shimmer height="20px" />
      </Box>
    ))}
  </MediaBox>
)

export default FilterAccordionLoading
