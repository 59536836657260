import { Button, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import styled from "styled-components"

import WithCollection from "areas/collection/hoc/with-collection"

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 8px 20px;
`

const StyledButton = styled(Button).attrs((props) => ({
  bg: "primary",
  color: "white",
  fontSize: "18px",
  height: "48px",
  lineHeight: "18px",
  width: "260px",
  ...props,
}))``

const InternalLoadMoreProducts = ({
  currentPage,
  loadingMoreProducts,
  next,
  resultsPerPage,
  totalProducts,
  updatePage,
}) => {
  const ref = useRef()

  return (
    <Container ref={ref}>
      <Typography color="grey.65" fontSize="14px" lineHeight="16px">
        Viewing {currentPage * resultsPerPage} of {totalProducts} Products
      </Typography>
      <StyledButton
        data-cy="collection-load-product-button"
        loading={loadingMoreProducts}
        onClick={() => {
          updatePage(next)
        }}
      >
        {loadingMoreProducts ? "Loading" : "View More"}
      </StyledButton>
    </Container>
  )
}

InternalLoadMoreProducts.propTypes = {
  currentPage: PropTypes.number,
  loadingMoreProducts: PropTypes.bool,
  next: PropTypes.number,
  resultsPerPage: PropTypes.number,
  totalProducts: PropTypes.number,
  updatePage: PropTypes.func,
}

const LoadMoreProducts = WithCollection(InternalLoadMoreProducts)

export default LoadMoreProducts
