import { Box, Image } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import useWindowDimensions from "helpers/window-dimensions"

const Loader = styled(Image)`
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
`
let scrollTick = false
const ProductsLoading = ({ productAreaRef }) => {
  const [margin, setMargin] = useState("250px")
  const { isDesktop } = useWindowDimensions()

  useEffect(() => {
    document.addEventListener("scroll", requestScroll)
    if (window.scrollY > 250) {
      console.log("INIT: ", window.scrollY)
      setMargin(`${window.scrollY}px`)
    }
    return () => {
      document.removeEventListener("scroll", requestScroll)
    }
  }, [])
  const requestScroll = () => {
    window.requestAnimationFrame(scrollListener)
  }
  const scrollListener = () => {
    if (!scrollTick) {
      scrollTick = true
      let scrollPos = window.scrollY
      const endScrollPos = productAreaRef.current.offsetHeight - 250
      const endScroll = productAreaRef.current && scrollPos > endScrollPos
      if (scrollPos > 250 && !endScroll) {
        setMargin(`${scrollPos}px`)
      } else if (endScroll) {
        setMargin(`${endScrollPos}px`)
      }
      scrollTick = false
    }
  }

  return (
    <Box position="relative">
      <Loader
        alt="loading"
        data-cy="collection-loading-icon"
        src={require("images/layout/loadinglarge.gif")}
        style={{ marginTop: isDesktop ? margin : "50px" }}
        width="175px"
      />
    </Box>
  )
}

ProductsLoading.propTypes = {
  productAreaRef: PropTypes.object,
}

export default ProductsLoading
