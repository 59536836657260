import { Box } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"
import { connect } from "react-redux"

import { fetchWishList } from "app-store-v2/wishlist/actions"
import { useIntersection } from "helpers/use-intersection"

export default function fetchWishlist(WrappedComponent) {
  const InternalFetchWishlist = (props) => {
    const { loadWishlist, wishlistLoaded } = props
    const ref = useRef()
    const inViewport = useIntersection(ref, "50px")

    const doLoadWishlist = useCallback(() => loadWishlist(), [])

    useEffect(() => {
      if (!wishlistLoaded && inViewport) {
        doLoadWishlist()
      }
    }, [inViewport, wishlistLoaded])

    return (
      <Box ref={ref}>
        <WrappedComponent {...props} />
      </Box>
    )
  }

  InternalFetchWishlist.propTypes = {
    loadWishlist: PropTypes.func,
    wishlistLoaded: PropTypes.bool,
  }

  const mapStateToProps = (state) => {
    return {
      wishlistLoaded: state.wishlist.show,
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      loadWishlist: () => {
        dispatch(fetchWishList(true))
      },
    }
  }

  const fetchWishlist = connect(
    mapStateToProps,
    mapDispatchToProps
  )(InternalFetchWishlist)

  return fetchWishlist
}
