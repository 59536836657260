import { faFilter } from "@jewlr/pro-regular-svg-icons/faFilter"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  Divider,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import withFilters from "areas/collection/hoc/with-filters"

import HolidayDeliveryToggle from "./holiday-delivery-toggle"
import SortMenu from "./sort-menu"

const FilterButton = styled(Button).attrs((props) => ({
  alignItems: "center",
  color: "black",
  fontSize: "16px",
  fontWeight: 600,
  letterSpacing: "1px",
  lineHeight: "25px",
  variant: "text",
  ...props,
}))``

const InternalFilterHeader = ({
  collectionFilters,
  disableFilters,
  disableSort,
  toggleFilter,
}) => {
  return (
    <>
      <Divider bg="grey.10" />
      <Box position="relative">
        <Flex alignItems="center" justifyContent="space-between">
          {!disableFilters ? (
            <FilterButton
              data-cy="collection-filter-icon-mobile"
              onClick={() => toggleFilter(true)}
            >
              <FontAwesomeIcon fontSize="14px" icon={faFilter} mr={1} />
              Filter
            </FilterButton>
          ) : (
            <Box />
          )}
          {!disableSort && <SortMenu />}
        </Flex>
      </Box>
      <Divider bg="grey.10" />
      {(collectionFilters || [])
        .filter((filter) => filter.delivery_category)
        .map((filter, index) => (
          <HolidayDeliveryToggle
            filter={filter}
            key={`${filter.name}${index}`}
          />
        ))}
    </>
  )
}

InternalFilterHeader.propTypes = {
  collectionFilters: PropTypes.array,
  disableFilters: PropTypes.bool,
  disableSort: PropTypes.bool,
  toggleFilter: PropTypes.func,
}

const FilterHeader = withFilters(InternalFilterHeader)

export default FilterHeader
