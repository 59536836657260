import { Button, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"

const InternalDescription = ({ longSubtitle, subtitle }) => {
  const [showDescription, toggleDescription] = useState(false)
  return (
    <div>
      <Typography fontSize={{ _: "15px", tablet: "18px" }}>
        {showDescription ? `${subtitle} ${longSubtitle}` : subtitle}{" "}
        {!!longSubtitle && (
          <Button
            color="grey.65"
            onClick={() => toggleDescription(!showDescription)}
            underline
            variant="text"
          >
            {showDescription ? "Less" : "More"}
          </Button>
        )}
      </Typography>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    longSubtitle: state.collection.long_subtitle,
    subtitle: state.collection.subtitle,
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalDescription.propTypes = {
  longSubtitle: PropTypes.string,
  subtitle: PropTypes.string,
}

const Description = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalDescription)

export default Description
