import React from "react"
import { connect } from "react-redux"

import {
  clearCategoryFilters,
  toggleFilterMenu,
  toggleSortMenu,
  setPriceRange,
  updateFilter,
  updateUniqueFilter,
  updatePriceRange,
  updateCurrentSort,
} from "areas/collection/store/actions"

export default function withFilters(WrappedComponent) {
  const InternalWithFilters = (props) => {
    return <WrappedComponent {...props} />
  }

  const checkFilter = (filters) => {
    if (filters.length == 0) return false

    return filters.some((filter) => {
      return filter.categories.some((category) => category.is_active_filter)
    })
  }

  const getCurrentSort = (state) => {
    const sortValue = state.collection.sort_menu.current_sort
    if (sortValue) {
      return state.collection.sort_menu.options.find(
        (option) => option.sort === sortValue
      )
    }
    return null
  }

  // Add 'Price' to filters
  const getFilters = (filters) => {
    if (filters.length == 0) return null
    return [{ name: "Price" }, ...filters]
  }

  // Get array of active filters
  const getTags = (filters) => {
    if (!filters) return []

    return filters.reduce((tags, filter) => {
      filter.categories.forEach((category) => {
        if (category.is_active_filter) {
          tags.push(category)
        }
      })
      return tags
    }, [])
  }

  const mapStateToProps = (state) => {
    let tags_filtered_by_experiment = []
    if (state.collection.filter_menu) {
      tags_filtered_by_experiment = state.collection.filter_menu.all_collection_filters.filter(
        (c) => c.design == state.collection.experiment_variation
      )
    }
    return {
      collectionFilters:
        state.collection.filter_menu && tags_filtered_by_experiment,
      collectionFiltersWithPrice: getFilters(tags_filtered_by_experiment),
      currentSort: getCurrentSort(state),
      defaultPriceRange: state.collection.filter_menu.default_price_range,
      disableFilters: state.collection.filter_menu.disable_filters,
      disableSort: state.collection.sort_menu.disable_sort,
      hasFilters: checkFilter(tags_filtered_by_experiment),
      openedFilter: state.collectionUI.open_filter_menu,
      openedSort: state.collectionUI.open_sort_menu,
      priceFilter: false,
      sortMenu: state.collection.sort_menu,
      tags: getTags(tags_filtered_by_experiment),
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      clearFilters: (e, category) => {
        e.preventDefault()
        dispatch(clearCategoryFilters(category))
      },
      clearTags: () => {
        dispatch(clearCategoryFilters("all"))
      },
      closeModal: () => {
        dispatch(toggleFilterMenu(false))
      },
      dispatch,
      removeFilter: (e, filter) => {
        e.preventDefault()
        dispatch(updateFilter(filter))
      },
      setFilter: (e, filter, unique, belongs_to) => {
        if (unique) {
          // deselect all other checkboxes in the category, then check the one requested
          dispatch(updateUniqueFilter({ belongs_to, filter }))
        } else {
          dispatch(updateFilter(filter))
        }
      },
      toggleFilter: (open) => {
        dispatch(toggleFilterMenu(open))
      },
      toggleSort: (open) => {
        dispatch(toggleSortMenu(open))
      },
      updateSort: (option) => {
        dispatch(updateCurrentSort(option))
        dispatch(toggleSortMenu(false))
      },
    }
  }

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { defaultPriceRange } = stateProps
    const { dispatch } = dispatchProps

    return {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      clearAllTags: (e) => {
        e.preventDefault()
        dispatch(clearCategoryFilters("all"))
        dispatch(setPriceRange(defaultPriceRange.min, defaultPriceRange.max))
      },
      clearPriceFilter: (e) => {
        e.preventDefault()
        dispatch(updatePriceRange(defaultPriceRange.min, defaultPriceRange.max))
      },
    }
  }

  const WithFilters = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(InternalWithFilters)

  return WithFilters
}
