import PropTypes from "prop-types"
import React from "react"

import withFilters from "areas/collection/hoc/with-filters"

import FilterPanel from "./filter-panel"
import HolidayDeliveryToggle from "./holiday-delivery-toggle"

/**
 * Collection filters accordion
 * @param  {array} collectionFilters - all filter categories in collection
 */
const InternalFilterAccordion = ({ collectionFilters, isMobile }) => {
  if (!collectionFilters) return null

  return collectionFilters.map((filter) =>
    filter.delivery_category ? (
      <HolidayDeliveryToggle filter={filter} key={filter.name} />
    ) : (
      <FilterPanel filter={filter} isMobile={isMobile} key={filter.name} />
    )
  )
}

InternalFilterAccordion.propTypes = {
  collectionFilters: PropTypes.array,
  isMobile: PropTypes.bool,
}

const FilterAccordion = withFilters(InternalFilterAccordion)

export default FilterAccordion
