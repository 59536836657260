import { Box, Video } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"

import ImageBackgroundBanner from "components/landing/image-background-banner"

import BannerLabel from "./banner-label"

const Banner = ({ firstElement, lazyload, medias }) => {
  return medias.map((media, i) => {
    const firstSubElement = i === 0 && firstElement

    if (media.type === "video") {
      const video = (
        <Video
          key={`collection_media${i}`}
          {...media.video}
          lazyload={lazyload}
        />
      )
      if (firstSubElement) {
        return (
          <Box as="h1" key={`video-${i}`} m={0}>
            {video}
          </Box>
        )
      }
      return video
    } else {
      return (
        <Box key={`collection_media${i}`} position="relative">
          <ImageBackgroundBanner
            element={{ ...media, style: { mb: 0, ...media.style } }}
            firstElement={firstSubElement}
            lazyload={lazyload}
          />
          {false && <BannerLabel color={media.style?.color} />}
        </Box>
      )
    }
  })
}

Banner.propTypes = {
  firstElement: PropTypes.bool,
  lazyload: PropTypes.bool,
  media: PropTypes.array,
}

export default Banner
