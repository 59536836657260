import { Flex, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { jewlr_t } from "helpers/application"

const Container = styled(Flex)`
  bottom: 16px;
  font-size: 16px;
  justify-content: center;
  position: absolute;
  width: 100%;
  ${(props) => props.theme.mediaQueries.tablet`
    font-size: 18px;
    justify-content: flex-start;
    right: 2%;
    width: auto;
  `}
`

const Label = styled(Typography)`
  font-weight: 700;
  line-height: 20px;
`

const BannerLabel = ({ color }) => {
  const labels = [
    "Select a Style",
    `${jewlr_t("terms.Personalize")} It`,
    "Made For You",
  ]

  return (
    <Container gutter={{ _: "3px", mobile: 1 }} ml={0} mr={0}>
      {labels.map((label, i) => (
        <Label color={color || "white"} key={label}>
          <span>{i + 1}|&nbsp;</span>
          {label}
        </Label>
      ))}
    </Container>
  )
}

BannerLabel.propTypes = {
  color: PropTypes.string,
}

export default BannerLabel
