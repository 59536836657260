import PropTypes from "prop-types"
import React from "react"
import styled, { keyframes } from "styled-components"

import ItemContainer from "./item-container"

const shimmer = keyframes`
  from {
    background-position: -256px 0;
  }
  to {
    background-position: 256px 0;
  }
`

const Image = styled.img`
  animation: ${shimmer} 1.5s forwards infinite;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: auto;
  max-height: 256px;
  max-width: 100%;
  overflow: hidden;
  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
    max-width: 256px;
  `}
  ${(props) =>
    props.loaded &&
    `
    min-height: 50px;
  `}
`

const PlaceImage = ({ index }) => {
  const productImage = require(`images/collections/placeholder_masks/ring_${
    (index % 6) + 1
  }.svg`)

  return <Image src={productImage} />
}

PlaceImage.propTypes = {
  index: PropTypes.number,
}

const Placeholder = ({ gridView, index }) => (
  <ItemContainer gridView={gridView}>
    <PlaceImage index={index} />
  </ItemContainer>
)

Placeholder.propTypes = {
  gridView: PropTypes.string,
  index: PropTypes.number,
}

export default Placeholder
