import { Box, Button, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

const GenericNotFound = ({ staticContext = {} }) => {
  staticContext.status = 404

  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
      </Helmet>
      <Box contain py={8} textAlign="center">
        <Box
          as="img"
          mb={2}
          src={`${sl.config.jewlr_asset_url}images/icons/jewlr/404.svg`}
          width="400"
        />
        <Typography mb={3} variant="body3">
          We&apos;re sorry, the page that you are looking for cannot be found.
        </Typography>
        <Button
          as={Link}
          data-lc="not-found"
          fontSize={14}
          letterSpacing="normal"
          mb={2}
          outline="black"
          px={3}
          py={1}
          radius="0"
          to="/"
        >
          CONTINUE SHOPPING
        </Button>
      </Box>
    </>
  )
}

GenericNotFound.propTypes = {
  staticContext: PropTypes.object,
}

export default GenericNotFound
